import React from "react"

//import GatsbyConfig from '../../gatsby-config'


import 'isomorphic-fetch'; //index file create
import Suwak from "../../../layouts/Suwak"



// Import layouts here
import Menu from "../../../layouts/menu";
import Capsules from "../../../layouts/capsules";
import Footer from "../../../layouts/footer";

import JarsGalleryBig from "../../../layouts/JarsGalleryBig";



// Import styles here
import '../../../styles/global.css';
import '../../../styles/capsules.css';
// import './JarsgalleryCSS.css';


// plugins
import { Helmet }   from "react-helmet";
import i18next from '../../../i18n/config';
// import favicon from '../img/favi_icon_16px.png';
//      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />

const $ = require("jquery");



export default  class Home extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        i18next.changeLanguage(this.props.pageContext.language, (err, t) => {
        });


        return (
                <React.Fragment>
                    <div className="App" >
                        <Helmet>
                            <meta charSet="utf-8" />
                            <link rel="canonical" href="https://www.instanta.pl/" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="icon" type="image/png" href={require('../../../img/template/favicon.png')} sizes="16x16" />
                            <title>Instanta Coffee Brand</title>
                            <meta name="Description" content="The company specializes in the production of private label coffee" />
                            <meta name="Keywords" content="private label coffee" />
                        </Helmet>
                        <div class="wrapper">
                            <Menu/>
                            <Capsules/>
                            <Footer/>
                            <Suwak/>

                        </div>

                    </div>


                </React.Fragment>

                )
    }
}