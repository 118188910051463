import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import ImgCapsulesIcon1 from "../img/J_kapsulki_16/kapsulki_kawa.png";
import ImgCapsulesIcon2 from "../img/J_kapsulki_16/kapsulki_kawa_mleko.png";
import ImgCapsulesIcon3 from "../img/J_kapsulki_16/kapsulki_rozpuszczalna.png";

import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";


import ImgCapsulesGroundMain from "../img/J_kapsulki_16/1_kapsulki_kawa_mielona/espresso.png";
import ImgCapsulesGroundCapsules from "../img/J_kapsulki_16/1_kapsulki_kawa_mielona/espresso_lungo_americano.png";
import ImgCapsulesMilkMain from "../img/J_kapsulki_16/2_kapsulka_kawa_mleko/cappuccino.png";
import ImgCapsulesMilkCapsules from "../img/J_kapsulki_16/2_kapsulka_kawa_mleko/cappuccino_latte.png";
import ImgCapsulesInstantMain from "../img/J_kapsulki_16/3_kapsulka_rozpuszczalna/au_lait.png";
import ImgCapsulesInstantCapsules from "../img/J_kapsulki_16/3_kapsulka_rozpuszczalna/cortado_au_lait.png";



export default function Header(props) {



    return (
            <React.Fragment>
                <section id="capsules" class="ourcoffees firstSection">
                    <h1>{i18next.t('CapsulesH1')}</h1>
                    <p>{i18next.t('CapsulesP')}</p>

                    <div class="icons">
                        <a>
                            <div class="icon" onClick={() => document.scrolltosection("capsules-ground")} >
                                <div><img loading="lazy" src={ImgCapsulesIcon1} alt="Instanta Capsules with ground coffee" /></div>
                                <p>{i18next.t('CapsulesMainLink1')}</p>

                            </div>
                        </a>

                        <a>
                            <div class="icon" onClick={() => document.scrolltosection("capsules-milk")} >
                                <div><img loading="lazy" src={ImgCapsulesIcon2} alt="Instanta Capsules with milk-coffee" /></div>
                                <p>{i18next.t('CapsulesMainLink2')}</p>
                            </div>
                        </a>
                        <a>
                            <div class="icon" onClick={() => document.scrolltosection("capsules-instant")} >
                                <div><img loading="lazy" src={ImgCapsulesIcon3} alt="Instanta Capsules with instanta coffee" /></div>
                                <p>{i18next.t('CapsulesMainLink3')}</p>
                            </div>
                        </a>
                    </div>
                    <aside class="mininavi">
                        <div>
                            <Link to={'/OurPackaging/Packaging/'}>
                            <img loading="lazy" src={ImgOurCoffeeBack} alt="Instanta Coffee Back Icon" />
                            <p>{i18next.t('CapsulesMiniNaviBack')}</p>
                            </Link>
                        </div>
                    </aside>
                </section>

                <section id="capsules-ground" class="freeze-dried main">
                    <div class="image"><img loading="lazy" src={ImgCapsulesGroundMain} loading="lazy" alt="Instanta Coffee Capsules with Ground Coffee" />
                    </div>
                    <div class="text">
                        <h2>{i18next.t('CapsulesGroundH2')}</h2>
                        <p>{i18next.t('CapsulesGroundP')}</p>
                        <div class="capsules-image"><img loading="lazy" src={ImgCapsulesGroundCapsules} loading="lazy" alt="Instanta Coffee Capsules with Ground Coffee" />
                        </div>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CapsulesLinkCollaboration')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design/'}>
                                    <p>{i18next.t('CustomProductLink3')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="capsules-milk" class="powdered main">
                    <div class="image"><img loading="lazy" src={ImgCapsulesMilkMain} alt="Instanta Coffee Capsules with Milk Coffee" />
                    </div>
                    <div class="text">
                        <h2>{i18next.t('CapsulesMilkH2')}</h2>
                        <p>{i18next.t('CapsulesMilkP')}</p>
                        <div class="capsules-image"><img loading="lazy" src={ImgCapsulesMilkCapsules} loading="lazy" alt="Instanta Coffee Capsules with Milk Coffee" />
                        </div>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CapsulesLinkCollaboration')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design/'}>
                                    <p>{i18next.t('CustomProductLink3')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="capsules-instant" class="granulated main">
                    <div class="image"><img loading="lazy" src={ImgCapsulesInstantMain} alt="Instanta Coffee Capsules with Instant Coffee" /></div>

                    <div class="text">
                        <h2>{i18next.t('CapsulesInstantH2')}</h2>
                        <p>{i18next.t('CapsulesInstantP')}</p>
                        <div class="capsules-image"><img loading="lazy" src={ImgCapsulesInstantCapsules} loading="lazy" alt="Instanta Coffee Capsules with Instant Coffee" />
                        </div>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CapsulesLinkCollaboration')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design/'}>
                                    <p>{i18next.t('CustomProductLink3')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

            )


}